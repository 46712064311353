import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import UserContext from "../contexts/UserContext";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const WorkoutPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext); // Ensure user is authenticated
  const { workoutType, duration } = location.state || {
    workoutType: null,
    duration: null,
  };

  const [exercises, setExercises] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadingExercise, setLoadingExercise] = useState(null);

  useEffect(() => {
    // Redirect to login if user is not authenticated
    if (!user) {
      navigate("/login");
    }
    // Redirect to home if workoutType or duration is missing
    if (!workoutType || !duration) {
      navigate("/home");
    }
  }, [user, workoutType, duration, navigate]);

  const formattedWorkoutType = workoutType
    ? workoutType.replace("_", " ").replace(/\b\w/g, (char) => char.toUpperCase())
    : "Unknown";

  const motivationalQuotes = [
    "Push yourself, because no one else is going to do it for you.",
    "Success starts with self-discipline.",
    "What seems impossible today will one day become your warm-up.",
    "Motivation is what gets you started. Habit is what keeps you going.",
  ];

  const [currentQuote, setCurrentQuote] = useState(motivationalQuotes[0]);

  // Rotate motivational quotes
  useEffect(() => {
    const quoteInterval = setInterval(() => {
      setCurrentQuote((prev) => {
        const currentIndex = motivationalQuotes.indexOf(prev);
        const nextIndex = (currentIndex + 1) % motivationalQuotes.length;
        return motivationalQuotes[nextIndex];
      });
    }, 3000);

    return () => clearInterval(quoteInterval);
  }, []);

  // Fetch initial exercises
  useEffect(() => {
    const fetchExercises = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/generate-exercises`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ workout_type: workoutType, duration }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setExercises(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    if (workoutType && duration) fetchExercises();
  }, [workoutType, duration]);

  // Replace individual exercise
  const replaceExercise = async (id) => {
    setLoadingExercise(id);
    try {
      const response = await fetch(`${API_BASE_URL}/generate-exercises`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          workout_type: workoutType,
          duration,
          existing_titles: exercises.map((exercise) => exercise.title),
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const newExercise = (await response.json())[0];
      setExercises((prev) =>
        prev.map((exercise) =>
          exercise.id === id ? { ...newExercise, id } : exercise
        )
      );
    } catch (err) {
      setError(err.message);
    } finally {
      setLoadingExercise(null);
    }
  };

  const [expanded, setExpanded] = useState({});

  const toggleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  if (loading) {
    return (
      <div
        style={{
          position: "relative",
          height: "calc(100vh - 64px - 20px)",
          width: "100vw",
          border: "8px solid",
          borderImage: "linear-gradient(to right, #6a11cb, #2575fc) 1",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f7f7f7",
          boxSizing: "border-box",
        }}
      >
        <CircularProgress size={80} style={{ color: "#6a11cb" }} />
        <Typography
          variant="h6"
          style={{
            marginTop: "20px",
            fontSize: "1.5rem",
            color: "#333",
            textAlign: "center",
          }}
        >
          {currentQuote}
        </Typography>
      </div>
    );
  }

  if (error) {
    return (
      <Typography
        variant="body1"
        color="error"
        style={{ marginTop: "20px", textAlign: "center" }}
      >
        Error: {error}
      </Typography>
    );
  }

  return (
    <Grid container direction="column" alignItems="center" style={{ padding: "20px" }}>
      {/* Header Section */}
      <Grid
        item
        xs={12}
        style={{
          width: "100%",
          padding: "40px 20px",
          background: "linear-gradient(to right, #6a11cb, #2575fc)",
          color: "white",
          textAlign: "center",
          borderRadius: "8px",
          marginBottom: "20px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Typography
          variant="h4"
          style={{
            fontWeight: "bold",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)",
          }}
        >
          Your {duration} Minute {formattedWorkoutType} Workout
        </Typography>
      </Grid>

      {/* Exercise Cards Section */}
      <Grid
        container
        item
        spacing={3}
        justifyContent="center"
        style={{ maxWidth: "1200px" }}
      >
        {exercises.map((exercise) => {
          const isExpanded = expanded[exercise.id];
          const truncatedDescription =
            exercise.description.length > 150 && !isExpanded
              ? exercise.description.slice(0, 150) + "..."
              : exercise.description;

          return (
            <Grid item xs={12} sm={6} md={4} key={exercise.id}>
              <Card
                style={{
                  padding: "10px",
                  height: "350px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <CardContent style={{ flexGrow: 1 }}>
                  <Typography variant="h6" color="primary">
                    {exercise.title || "No Title Available"}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={{ marginBottom: "10px" }}>
                    {truncatedDescription}
                  </Typography>
                  {!isExpanded && exercise.description.length > 150 && (
                    <Button
                      size="small"
                      onClick={() => toggleExpand(exercise.id)}
                      style={{ textTransform: "none" }}
                    >
                      View More
                    </Button>
                  )}
                  {isExpanded && (
                    <Button
                      size="small"
                      onClick={() => toggleExpand(exercise.id)}
                      style={{ textTransform: "none" }}
                    >
                      View Less
                    </Button>
                  )}
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    {exercise.reps || "Reps not specified"}
                  </Typography>
                </CardContent>

                <div
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    bottom: "20px",
                    width: "100%",
                  }}
                >
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={() => replaceExercise(exercise.id)}
                    disabled={loadingExercise === exercise.id}
                  >
                    {loadingExercise === exercise.id ? "Replacing..." : "Replace Exercise"}
                  </Button>
                </div>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default WorkoutPage;
