import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  TextField,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserContext from "../contexts/UserContext";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login, user } = useContext(UserContext); // Include user for redirect logic

  // Redirect to /home if user is already logged in
  useEffect(() => {
    if (user) {
      navigate("/home");
    }
  }, [user, navigate]);

  const handleLogin = async () => {
    setError(null); // Clear previous errors
    setLoading(true);
    try {
      await login(email, password); // Authenticate the user
      navigate("/home"); // Redirect to HomePage after successful login
    } catch (err) {
      setError(err.message || "Login failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const isButtonDisabled = !email || !password;

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{
        minHeight: "100vh",
        padding: "20px",
        background: "linear-gradient(to right, #6a11cb, #2575fc)", // Gradient background
      }}
    >
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={4}
        style={{
          background: "white",
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          width: "100%",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          style={{
            fontWeight: "bold",
            marginBottom: "20px",
            color: "#333",
          }}
        >
          Login to Your Account
        </Typography>
        {error && (
          <Alert severity="error" style={{ marginBottom: "20px" }}>
            {error}
          </Alert>
        )}
        <TextField
          label="Email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginBottom: "20px" }}
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginBottom: "20px" }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
          disabled={isButtonDisabled || loading}
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            marginBottom: "20px",
          }}
        >
          {loading ? <CircularProgress size={24} style={{ color: "white" }} /> : "Login"}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => navigate("/signup")}
          style={{ padding: "10px 20px", fontSize: "16px" }}
        >
          Need an account? Sign Up
        </Button>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
