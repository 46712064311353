import React, { useContext } from "react";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserContext from "../contexts/UserContext";

const Navbar = () => {
  const { token, logout } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <AppBar
      position="static"
      style={{
        background: "linear-gradient(to right, #6a11cb, #2575fc)",
        marginBottom: "20px",
      }}
    >
      <Toolbar>
        <Typography
          variant="h6"
          style={{
            flexGrow: 1,
            fontWeight: "bold",
            cursor: "pointer",
          }}
          onClick={() => navigate(token ? "/home" : "/")}
        >
          Your Workout Planner
        </Typography>
        {token ? (
          <>
            <Button
              color="inherit"
              onClick={() => navigate("/home")}
              style={{
                borderRadius: "20px",
                background: "white",
                color: "#6a11cb",
                marginRight: "10px",
              }}
            >
              Create Workout
            </Button>
            <Button
              color="inherit"
              onClick={() => {
                logout();
                navigate("/"); // Ensure navigation to LandingPage
              }}
              style={{
                borderRadius: "20px",
                background: "#ff4b5c",
                color: "white",
              }}
            >
              Logout
            </Button>
          </>
        ) : (
          <>
            <Button
              color="inherit"
              onClick={() => navigate("/login")}
              style={{
                borderRadius: "20px",
                background: "white",
                color: "#6a11cb",
                marginRight: "10px",
              }}
            >
              Login
            </Button>
            <Button
              color="inherit"
              onClick={() => navigate("/signup")}
              style={{
                borderRadius: "20px",
                background: "#2575fc",
                color: "white",
              }}
            >
              Sign Up
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
