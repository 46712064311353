import React, { createContext, useState, useEffect } from "react";

const UserContext = createContext();
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem("token"));

    // Automatically retrieve user data when a valid token is present
    useEffect(() => {
        if (token) {
            const fetchUser = async () => {
                try {
                    const response = await fetch(`${API_BASE_URL}/protected`, {
                        method: "GET",
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setUser(data.username || data.email); // Ensure user data is set correctly
                    } else {
                        logout(); // Logout if the token is invalid
                    }
                } catch (error) {
                    console.error("Failed to fetch user info:", error);
                    logout();
                }
            };
            fetchUser();
        }
    }, [token]);

    const login = async (email, password) => {
        try {
            const response = await fetch(`${API_BASE_URL}/login`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, password }), // Using email for login
            });
            const data = await response.json();
            if (response.ok) {
                setToken(data.access_token);
                localStorage.setItem("token", data.access_token);
                setUser(email); // Save email as user identifier
            } else {
                throw new Error(data.detail || "Login failed");
            }
        } catch (error) {
            console.error("Login error:", error);
            throw error;
        }
    };

    const logout = () => {
        setUser(null);
        setToken(null);
        localStorage.removeItem("token"); // Clear token from localStorage
    };

    const signup = async (email, password) => {
        try {
            const response = await fetch(`${API_BASE_URL}/signup`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, password }), // Using email for signup
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.detail || "Signup failed");
            }
            return data.msg; // Optionally return success message
        } catch (error) {
            console.error("Signup error:", error);
            throw error;
        }
    };

    return (
        <UserContext.Provider value={{ user, setUser, token, setToken, login, logout, signup }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
