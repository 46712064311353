import React, { useState, useContext } from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserContext from "../contexts/UserContext";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setToken, setUser } = useContext(UserContext); // Add setUser to ensure user state is updated

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignup = async () => {
    if (!email || !password) {
      setError("Both email and password are required.");
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    if (password.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const signupResponse = await fetch(`${API_BASE_URL}/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      if (!signupResponse.ok) {
        const { detail } = await signupResponse.json();
        throw new Error(detail || `HTTP error! status: ${signupResponse.status}`);
      }

      const loginResponse = await fetch(`${API_BASE_URL}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      if (!loginResponse.ok) {
        throw new Error("Login failed after signup.");
      }

      const loginData = await loginResponse.json();
      setToken(loginData.access_token);
      setUser(email); // Update user state
      localStorage.setItem("token", loginData.access_token); // Persist token
      navigate("/home"); // Redirect user to the HomePage
    } catch (err) {
      setError(err.message || "Signup failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{
        minHeight: "100vh",
        padding: "20px",
        background: "linear-gradient(to right, #6a11cb, #2575fc)",
      }}
    >
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={4}
        style={{
          background: "white",
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          width: "100%",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          style={{
            fontWeight: "bold",
            marginBottom: "20px",
            color: "#333",
          }}
        >
          Create an Account
        </Typography>
        {error && (
          <Alert severity="error" style={{ marginBottom: "20px" }}>
            {error}
          </Alert>
        )}
        <TextField
          label="Email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginBottom: "20px" }}
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          helperText="Password must be at least 8 characters long."
          style={{ marginBottom: "20px" }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSignup}
          disabled={loading}
          style={{
            padding: "10px",
            fontSize: "16px",
            marginBottom: "20px",
          }}
        >
          {loading ? <CircularProgress size={24} style={{ color: "white" }} /> : "Sign Up"}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => navigate("/login")}
          style={{ padding: "10px", fontSize: "16px" }}
        >
          Already have an account? Log In
        </Button>
      </Grid>
    </Grid>
  );
};

export default SignupPage;
