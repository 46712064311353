import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const HomePage = () => {
  const [workoutType, setWorkoutType] = useState('');
  const [duration, setDuration] = useState('');
  const navigate = useNavigate();

  const isButtonDisabled = !workoutType || !duration;

  const handleStartWorkout = () => {
    navigate('/workout', { state: { workoutType, duration } });
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{
        minHeight: '100vh',
        padding: '20px',
        background: 'linear-gradient(to right, #6a11cb, #2575fc)', // Add gradient background
      }}
    >
      {/* Header Section */}
      <Grid
        item
        xs={12}
        style={{
          textAlign: 'center',
          marginBottom: '20px',
          color: 'white',
        }}
      >
        <Typography
          variant="h4"
          style={{
            fontWeight: 'bold',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)', // Add text shadow
          }}
        >
          Select Your Workout
        </Typography>
      </Grid>

      {/* Form Section */}
      <Grid
        container
        item
        xs={12}
        sm={8}
        md={6}
        lg={4}
        direction="column"
        alignItems="center"
        style={{
          background: 'white',
          borderRadius: '10px',
          padding: '20px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Grid item xs={12} style={{ width: '100%', marginBottom: '20px' }}>
          <FormControl fullWidth>
            <InputLabel>Workout Type</InputLabel>
            <Select
              value={workoutType}
              onChange={(e) => setWorkoutType(e.target.value)}
              label="Workout Type"
            >
              <MenuItem value="legs">Legs</MenuItem>
              <MenuItem value="arms">Arms</MenuItem>
              <MenuItem value="abs">Abs</MenuItem>
              <MenuItem value="full_body">Full Body</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ width: '100%', marginBottom: '20px' }}>
          <FormControl fullWidth>
            <InputLabel>Duration</InputLabel>
            <Select
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              label="Duration"
            >
              <MenuItem value={15}>15 Minutes</MenuItem>
              <MenuItem value={30}>30 Minutes</MenuItem>
              <MenuItem value={45}>45 Minutes</MenuItem>
              <MenuItem value={60}>60 Minutes</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={isButtonDisabled}
            onClick={handleStartWorkout}
            style={{
              padding: '10px 20px',
              fontSize: '16px',
            }}
          >
            Create Workout
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomePage;
